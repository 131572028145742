export class Document {
  #db;
  #id;
  #rev = undefined;
  #content;

  constructor(relatedDb, docId = 'default') {
    this.#db = relatedDb;
    this.#id = docId;
  }

  async init(relatedDb, docId) {
    if (relatedDb)
      this.#db = relatedDb;
    if (docId)
      this.#id = docId;
    
    return await this.#db.get(this.#id)
    .then((res) => {
      this.#rev = res._rev;
      this.#content = res;
      return true;
    }).catch(() => {
      console.error(`${this.#id} did not exist`);
      return false;
    })
  }

  async create(relatedDb, docId, initialData) {
    if (relatedDb)
      this.#db = relatedDb;
    if (docId)
      this.#id = docId;

    if (await this.#db.get(docId)
      .then(() => {
        console.error(`This document already exist : ${docId}`)
        return false
      }).catch(() => true)) {
    
      const data = {
        ...initialData,
        ...{
          _id: this.#id,
        }
      }

      this.#db.put(data)
      .then((res) => {
        this.#rev = res._rev;
      })
      .catch(() => console.error(`Unable to create document : ${this.#id}`))
    }
  }

  async get(relatedDb, docId) {
    if (relatedDb)
      this.#db = relatedDb;
    if (docId)
      this.#id = docId;

    return await this.#db.get(this.#id)
    .then((res) => {
      this.#rev = res._rev;
      this.#content = res;
      return res;
    }).catch(() => {
      console.error(`${this.#id} did not exist`);
      return undefined;
    })
  }

  async set(relatedDb, docId, data) {
    if (relatedDb)
      this.#db = relatedDb;
    if (docId)
      this.#id = docId;

    this.#db.put({
      ...data,
      ...{
        _id: this.#id,
        _rev: this.#rev
      }
    })
    .then((res) => {
      this.#rev = res._rev;
    })
  }
}