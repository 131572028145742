import React from 'react';

function Checkbox({reference, text, error, onChange, className}) {
 
  return (
    <div className={className}>
      <label className={`${error ? 'text-red-600' : null}`}>
        <input ref={reference} onChange={onChange} type="checkbox"/> {text}
      </label>
      <p className='ml-4 text-red-600 text-sm'>{error}</p>
    </div>
  );
}

export default Checkbox;
