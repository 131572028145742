import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Lose() {
  const navigate = useNavigate();
 
  useEffect(() => {
    setTimeout(() => navigate('/', { replace: true}), 15000);
  })

  return (
    <div className="flex flex-col w-tablette h-tablette justify-center bg-blue">
      <div className='ml-24 text-left text-white items-start'>
        <h1 className='text-7xl mb-10'> MIAM !</h1>
        <p className='text-3xl'>
          Tu n'as pas été sélectionné pour le tirage au sort. <br/>
          Tout n’est pas perdu, un bonbon t'attend à l’accueil 😊
        </p>
      </div>
    </div>
  );
}

export default Lose;
