import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlayerRepository } from '../../Utils/Repositories/PlayerRepository';
import Input from './Input/Input';
import Checkbox from './Input/CheckBox';
import Csv from '../../Utils/Csv';

function Register() {
  const navigate = useNavigate();

  const [playerRepository, setPlayerRepository] = useState();
  
  const [lastName, setLastName] = useState();
  const [lastNameError, setLastNameError] = useState();
  const [firstName, setFirstName] = useState();
  const [firstNameError, setFirstNameError] = useState();
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();
  const [tel, setTel] = useState();
  const [telError, setTelError] = useState();

  const [rulesError, setRulesError] = useState();

  const rulesRef = useRef();
  const optinRef = useRef();

  useEffect(() => {
    const tmp = new PlayerRepository();
    setPlayerRepository(tmp);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isError = false;
    
    const checkEmpty = (value, setter) => {
      if (!value || (value && value.length === 0)) {
        setter('Ce champ est obligatoire');
        isError = true;
      }
    }

    [
      [lastName, setLastNameError],
      [firstName, setFirstNameError],
      [email, setEmailError],
      [tel, setTelError]
    ].map((value) => checkEmpty(value[0], value[1]));

    const mailRegex = "[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*"
    if (email && !email.match(mailRegex)) {
      setEmailError('Votre mail semble incorrect');
      isError = true;
    }

    const phoneRegex = "[0-9]{10}"
    if (tel && !tel.match(phoneRegex)) {
      setTelError('Votre numéro semble incorrect');
      isError = true;
    }

    if (rulesRef.current.checked === false) {
      setRulesError('Ce champ est obligatoire');
      isError = true;
    }

    if (isError)
      return;
    
    await playerRepository.get().then(res => {
      if (res.find((value) => value.email === email)) {
        setEmailError('Il semblerait que vous soyez déjà inscrit');
        return;
      }

        const tmp = {
          lastName,
          firstName,
          email,
          tel,
          rules: rulesRef.current.checked,
          optin: optinRef.current.checked,
          winner: false,
        }
    
        playerRepository.add([tmp]).then(() => {
          navigate('/game', {
            replace: true,
            state: {
              data: tmp
            },
          });
        });
    });
  }

  const download = () => {
    console.log('DOWNLOAD');
    playerRepository.get().then(res => {
      const csv = new Csv();
      csv.formateData(res, true);
      csv.export();
    });
  }

  

  return (
    <div className='flex w-tablette h-tablette bg-blue items-center justify-center text-white'>
    <div className='flex w-2/3 flex-col items-center'>
      <img src={'/img/logo.png'} className='w-40pc' alt='toctocboo' onClick={() => navigate('/', {replace: true})}/>
      <form className='w-full'>
        <div className="flex mb-8">
          <div className="flex-1 mt-2 mb:mt-0 px-3">
            <div className="flex mb-4">
                <Input
                  label="Nom *"
                  className="flex-1 pr-3 "
                  onChange={(value) => {
                    setLastNameError(null);
                    setLastName(value)
                  }}
                  error={lastNameError}
                  inputProps={{
                    type: 'text',
                    name: 'last_name',
                    placeholder: 'Nom',
                    required: true,
                  }}
                />
                <Input
                  label="Prénom *"                 
                  className="flex-1 pl-3"
                  onChange={(value) => {
                    setFirstName(null);
                    setFirstName(value)
                  }}
                  error={firstNameError}
                  inputProps={{
                    type: 'text',
                    name: 'first_name',
                    placeholder: 'Prénom',
                    required: true,
                  }}
                />
            </div>
            <div className='flex mb-4'>
              <Input
                label="Email *"
                className="flex-1 pr-3"
                onChange={(value) => {
                  setEmailError(null);
                  setEmail(value)
                }}
                error={emailError}
                inputProps={{
                  type: 'email',
                  name: 'email',
                  placeholder: 'votre@mail.com',
                  required: true,
                }}
              />
              <Input
                label="Téléphone *"
                className="flex-2 pl-3"
                onChange={(value) => {
                  setTelError(null);
                  setTel(value);
                }}
                error={telError}
                inputProps={{
                  type: 'tel',
                  name: 'Téléphone',
                  placeholder: '0692 12 34 56',
                  required: true,
                }}
              />
            </div>
            <div>
              <Checkbox
                reference={rulesRef}
                text={"J'accepte le règlement du jeu (disponible à l'accueil) *"}
                onChange={() => {
                  setRulesError(null);
                }}
                error={rulesError}
                className=''
              />
              <Checkbox reference={optinRef} text={"J’accepte de recevoir la newsletter de French Bee"} className='mb-8' />
              <div className='flex justify-center'>
                <button onClick={handleSubmit} className='bg-frenchbee-red rounded-full px-8 py-5 text-white font-bold'>JOUER !</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      </div>
      <div onClick={download} className='absolute w-10 h-10 bottom-0 right-0 align-bottom'></div> 
    </div>
  );
}

export default Register;
