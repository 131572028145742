import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from '../Screens/Home/Home';
import Game from '../Screens/Game/Game';
import Win from '../Screens/Win/Win';
import Lose from '../Screens/Lose/Lose';
import Register from '../Screens/Register/Register';

export default function MainRouter() {
  const location = useLocation();

  return (
    <div className='flex flex-col w-full h-screen justify-center items-center'>
      <Routes location={location} key={location.pathname}>
        <Route index element={<Home />} />
        <Route path="/game" element={<Game />} />
        <Route path="/win" element={<Win />} />
        <Route path="/lose" element={<Lose />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </div>
  );
}