import React from 'react';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();

  const handleStart = () => {
    navigate('/register', { replace: true});
  }
  
  return (
    <div>
    <div className="flex flex-col w-tablette h-tablette items-center justify-center bg-home">
      <button className='text-white font-bold text-4xl bg-frenchbee-red px-20 py-8 rounded-3xl mt-16' onClick={handleStart}>JOUER</button>
    </div>
    </div>
  );
}

export default Home;
