import React, { useRef } from 'react';

function Input({label, className, inputProps, onChange, error}) {
  const inputRef = useRef();
  const handleChange = (e) => {
    e.preventDefault();
    onChange(e.target.value);
  }

  return (
    <div className={className}>
      <label className="block uppercase tracking-wide text-charcoal-darker text-sm font-bold ml-4 mb-2">{label}</label>
      <input
        {...inputProps}
        ref={inputRef}
        onChange={handleChange}
        className={`w-full shadow-inner text-black p-3 pl-6 border-0 rounded-full ${error ? 'placeholder-red-600 text-red-600 border-2 border-red-600' : null}`}
      />
      <p className='ml-4 text-red-600 text-sm'>{error}</p>
    </div>
  );
}

export default Input;
