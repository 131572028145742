export default class Csv {
  _data = [];

  formateData(data, set) {
    const header = ['Nom', 'Prénom', 'Email', 'Téléphone', 'Règlement', 'OPT-IN MAIL', 'GAGNANT'];

    if (!data || (data && data.length === 0)) {
      this.setData([header, ...[]])
      return [header, ...[]];
    }

    const tmpHeaders = Object.keys(data[0]);
    const content = data.map((value) => tmpHeaders.map(property => value[property]));
    if (set)
      this.setData([header, ...content]);
    return [header, ...content];
  }

  setData(data) {
    if (data)
      this._data = data;
  }

  export() {
    const csvContent = this._data.map(e => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: 'test/csv' });
    blob.lastModifiedDate = new Date();
    blob.name = 'users.csv';
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.setAttribute("href", url);
    link.setAttribute("download", "users.csv");
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
  }
}