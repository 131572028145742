import React, { useState, useEffect } from 'react';
import Door from './Door/Door';
import { useNavigate, useLocation } from 'react-router-dom';
import { PlayerRepository } from '../../Utils/Repositories/PlayerRepository';

function Game() {
  const navigate = useNavigate();
  const location = useLocation();
  const {data} = location.state;
  
  // States
  const [remove, setRemove] = useState(false);
  const [headerText, setHeaderText] = useState('Choisissez une porte')
  const [lock, setLock] = useState(false);
  const [playerRepository, setPlayerRepository] = useState();

  useEffect(() => {
    const tmp = new PlayerRepository();
    setPlayerRepository(tmp);
  }, []);

  const initialDoors = [
    {
      name: "monster1",
      width: 217,
      height: 239,
      open: false,
      winner: false,
    },
    {
      name: "monster2",
      width: 222,
      height: 207,
      open: false,
      winner: false,
    },
    {
      name: "winner",
      width: 108,
      height: 172,
      open: false,
      winner: true,
    },
  ];

  const shuffleDoors = (doors) => {
    let currentIndex = doors.length;
    let randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [doors[currentIndex], doors[randomIndex]] = [
        doors[randomIndex],
        doors[currentIndex],
      ];
    }

    return doors;
  };

  const shuffledDoors = shuffleDoors(initialDoors);

  const [doors, setDoors] = useState(shuffledDoors);

  const setWinner = (email, data) => {
    playerRepository.get().then(res => {
      if (!res || (res && res.length === 0))
        return false;
      playerRepository.set({attribute: 'email', id: email}, {...data, ...{winner: true}});
    });
  }

  const openDoor = (id) => {
    if (lock)
      return;

    setHeaderText('Toc-toc !')
    setTimeout(() => {
      const newDoors = doors.map((door, index) => {
        if (index === id) {
          return {
            ...door,
            open: true,
          };
        }
  
        return door;
      });
      setDoors(newDoors);
    }, 2000);
    setTimeout(() => {
      setHeaderText(doors[id].name === 'winner' ? 'GAGNÉ !' : 'Perdu...')
    }, 2750)
    setTimeout(() => {
      setRemove(true);
    }, 4500)
    setTimeout(() => {
      if (doors[id].name === 'winner') {
        setWinner(data.email, data);
        navigate('/win', { replace: true});
      } else {
          navigate('/lose', { replace: true});
      }
    }, 7500)
  };

  const lockDoors = () => {
    setLock(true);
  }

  return (
    <div className="flex flex-col w-tablette h-tablette items-center justify-center bg-fuselage">
      <p className='text-white font-black text-4xl mb-10 mt-[-5rem]'>{headerText}</p>
      <div className="flex items-center justify-center">
        {doors.map((door, id) => {
          const { name, width, height, open } = door;
          return (
            <Door
              key={`door-${id}`}
              name={name}
              width={width}
              height={height}
              open={open}
              openDoor={() => openDoor(id)}
              remove={remove}
              lock={lockDoors}
              locked={lock}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Game;
