import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Win() {
  const navigate = useNavigate();
 
  useEffect(() => {
    setTimeout(() => navigate('/', { replace: true }), 15000);
  });

  return (
    <div className="flex flex-col w-tablette h-tablette justify-center bg-blue">
      <div className='ml-24 text-left text-white items-start'>
        <h1 className='text-7xl mb-10'> BRAVO !</h1>
        <p className='text-3xl'>
        Tu accèdes au tirage au sort final du 06 novembre <br/>
        pour tenter de gagner un billet d’avion REUNION/USA !<br/>
        <br/>
        Connecte-toi dès le 07 novembre sur la page Facebook<br/>
        « French Bee Ile de La Réunion » pour connaître les Gagnants.<br/>
        </p>
      </div>
    </div>
  );
}

export default Win;
