import PouchDB from "pouchdb";
import { Document } from "./Document";

export class Database {
  #db;

  constructor(dbName) {
    this.#db = new PouchDB(dbName);
  }

  getInstance() {
    return this.#db;
  }

  async createDocument(docId = 'default', initialData) {
    const doc = new Document(this.#db, docId);
    
    await doc.create(this.#db, docId ,initialData);
    return doc;
  }

  async getDocument(docId = 'default') {
    const doc = new Document(this.#db, docId);
    if (!await doc.init()) {
      return false;
    } else
      return doc;
  }
}

const db = new Database('default')

export default db;