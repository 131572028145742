import db from "../Database/Database";

export class PlayerRepository {
  #doc;
  #data;
  #player_list = [];

  constructor() {
    db.getDocument('players').then((res) => {
      if (!res) {
        db.createDocument('players', {player_list: []}).then((res) => {
          this.#doc = res;
        });
      } else {
        this.#doc = res;
      }
    });
  }

  async add(data) {
    await this.#doc.get().then(res => {
      let tmp = {
        ...res,
        player_list: res.player_list.concat(data),
      };
      this.#doc.set(undefined, undefined, tmp)
      .then(() => {
        this.#player_list = tmp.player_list;
      });
    });
  }

  async get() {
    if (!this.#doc) {
      return undefined;
    }

    return await this.#doc.get().then(res => {
      this.#data = res;
      this.#player_list = this.#data.player_list;
      return this.#player_list;
    })
  }

  async set({attribute, id}, data) {
    await this.#doc.get().then((res) => {
      const userId = res.player_list.findIndex((value) => value[attribute] === id);
      let tmp = res;
      tmp.player_list[userId] = data;
      this.#doc.set(undefined, undefined, tmp)
      .then(() => {
        this.#player_list = tmp.player_list;
      });
    })
  }
}