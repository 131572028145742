import React from 'react';

import './Door.css';

export default function Door({ name, open, openDoor, width, height, remove, lock, locked }) {
  return (
    <div className={`flex w-234 h-427 mr-5 bg-${name}back items-center justify-center bg-no-repeat bg-center`}>
      <div className={`flex-auto z-10 door w-237 h-430 bg-door ${remove ? 'remove' : null} ${open ? 'doorOpen' : ''} bg-no-repeat bg-center`} onClick={() => {
        if (!locked) {
          openDoor();
          lock();
        }
      }}></div>
      <div className={`absolute z-0 w-234 h-427 bg-${name} bg-no-repeat bg-center`}></div>
    </div>
  );
};